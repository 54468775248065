import { createStore } from 'vuex'
import VuexPersist from 'vuex-persist'

const vuexPersist = new VuexPersist({
  key: 'lobsterlead',
  storage: window.localStorage,
})

const initialState = {
  toasts: [],
  maxToasts: 5,
  userData: {
    token: null,
    companyToken: null,
    email: null,
  },
  rememberMe: false,
  userEmail: '',
  language: '',
  selectedEntity: {},
  assetId: null,
  automaticPublishing: false,
  socialMedia: {
    instagramAuthenticated: false,
    facebook: false,
  },
  assetName: '',
  assetServiceType: '',
  assetLanguage: '',
  assetData: {
    assetId: null,
    serviceType: '',
    customerLanguageId: null,
    currentStep: 0,
    introductionList: {
      name: '',
      website: '',
      languageId: 1,
      searchEngineLocations: {
        google: [],
        bing: [],
        yandex: [],
      },
      communicationId: '',
    },
    informationList: {
      aboutUs: '',
      facebookUrl: '',
      instagramUrl: '',
      twitterUrl: '',
      logo: '',
      linkedinUrl: '',
      phone: '',
      wphone: '',
      keywords: [],
    },
    authList: {
      username: '',
      password: '',
      authUrl: '',
      isAuth: false,
    },
    sources: [{ sourceCategoryId: '', getUrl: '', addUrl: '', editUrl: '' }],
    loading: false,
    inputLoading: false,
    isDataReceived: false,
  },
  entityShareDates: {
    day: [],
    time: [],
  },
  assets: [],
  theme: {
    primaryColor: '#c2410c',
    secondaryColor: '#0c4a6e',
    accentColor: '#0284c7',
  },
  snackbarPosition: {
    bottom: true,
    right: true,
    top: false,
    left: false,
  },
}

export default createStore({
  state: initialState,
  mutations: {
    // Auth
    setUserData(state, userData) {
      state.userData = userData
    },
    clearUserData(state) {
      state.userData = {
        token: null,
        email: null,
        companyToken: null,
      }
    },
    setRememberMe(state, rememberMe) {
      state.rememberMe = rememberMe
    },
    setUserEmail(state, email) {
      if (state.rememberMe) {
        state.userEmail = email
      }
    },

    // Assets
    setAssets(state, assets) {
      state.assets = assets
    },
    setAssetDataField(state, { field, value }) {
      state.assetData[field] = value
    },
    setAssetId(state, id) {
      state.assetId = id
      state.assetData.assetId = id
    },
    setAutomaticPublishing(state, automaticPublishing) {
      state.automaticPublishing = automaticPublishing
    },
    setAssetServiceType(state, serviceType) {
      state.assetData.serviceType = serviceType
      state.assetServiceType = serviceType
    },
    setAssetName(state, name) {
      state.assetName = name
    },
    setAssetCustomerLanguageId(state, val) {
      state.assetData.customerLanguageId = val
    },
    setAssetLanguage(state, language) {
      state.assetLanguage = language
    },
    setCurrentStep(state, step) {
      state.assetData.currentStep = step
    },
    setIntroductionList(state, introductionList) {
      state.assetData.introductionList = introductionList || { name: '', website: '', languageId: 1, searchEngineLocations: { google: [], bing: [], yandex: [] } }
    },
    setInformationList(state, informationList) {
      state.assetData.informationList = informationList
    },
    setAuthList(state, authList) {
      state.assetData.authList = authList
    },
    setSources(state, sources) {
      state.assetData.sources = sources
    },
    setAssetLoading(state, loading) {
      state.assetData.loading = loading
    },
    setAssetInputLoading(state, inputLoading) {
      state.assetData.inputLoading = inputLoading
    },
    setIsDataReceived(state, isDataReceived) {
      state.assetData.isDataReceived = isDataReceived
    },
    resetAssetData(state) {
      state.assetData = {
        assetId: null,
        serviceType: '',
        currentStep: 0,
        introductionList: {
          name: '',
          website: '',
          languageId: 1,
          searchEngineLocations: {
            google: [],
            bing: [],
            yandex: [],
          },
          communicationId: '',
        },
        informationList: {
          aboutUs: '',
          facebook: '',
          instagram: '',
          twitter: '',
          keywords: [],
          logo: '',
          linkedin: '',
          phone: '',
          wphone: '',
        },
        authList: {
          username: '',
          password: '',
          authUrl: '',
          isAuth: false,
        },
        sources: [{ sourceCategoryId: '', getUrl: '', addUrl: '', editUrl: '' }],
        communicationId: '',
        loading: false,
        inputLoading: false,
        isDataReceived: false,
      }
    },
    setAssetEditData(state, assetData) {
      state.assetData.introductionList.name = assetData.name
      state.assetData.introductionList.website = assetData.website
      state.assetData.informationList.aboutUs = assetData.aboutus
      state.assetData.informationList.facebookUrl = assetData.facebookUrl ?? ''
      state.assetData.informationList.instagramUrl = assetData.instagramUrl ?? ''
      state.assetData.informationList.twitterUrl = assetData.twitterUrl ?? ''
      state.assetData.informationList.linkedinUrl = assetData.linkedinUrl ?? ''
      state.assetData.informationList.phone = assetData.phone ?? ''
      state.assetData.informationList.wphone = assetData.wphone ?? ''
      state.assetData.sources = assetData.sources
      state.assetData.communicationId = assetData.communicationId
    },

    // Entity
    setSelectedEntity(state, entity) {
      state.selectedEntity = entity
    },
    setEntityShareDates(state, entityShareDates) {
      state.entityShareDates = entityShareDates
    },

    // Snackbar Position
    setSnackbarPosition(state, position) {
      if (position.bottom) {
        state.snackbarPosition.bottom = true
        state.snackbarPosition.top = false
      } else if (position.top) {
        state.snackbarPosition.bottom = false
        state.snackbarPosition.top = true
      }

      if (position.right) {
        state.snackbarPosition.right = true
        state.snackbarPosition.left = false
      } else if (position.left) {
        state.snackbarPosition.right = false
        state.snackbarPosition.left = true
      }
    },

    setLanguage(state, language) {
      state.language = language
    },
  },
  actions: {
    // Asset related actions
    setAssetId({ commit }, id) {
      commit('setAssetId', id)
    },
    setAutomaticPublishing({ commit }, automaticPublishing) {
      commit('setAutomaticPublishing', automaticPublishing)
    },
    setAssetName({ commit }, name) {
      commit('setAssetName', name)
    },
    setAssetServiceType({ commit }, serviceType) {
      commit('setAssetServiceType', serviceType)
    },
    setAssetCustomerLanguageId({ commit }, val) {
      commit('setAssetCustomerLanguageId', val)
    },
    setAssetLanguage({ commit }, language) {
      commit('setAssetLanguage', language)
    },
    setIsDataReceived({ commit }, isDataReceived) {
      commit('setIsDataReceived', isDataReceived)
    },
    setAssets({ commit }, assets) {
      commit('setAssets', assets)
    },
    setCurrentStep({ commit }, step) {
      commit('setCurrentStep', step)
    },
    setIntroductionList({ commit }, introductionList) {
      commit('setIntroductionList', introductionList)
    },
    setInformationList({ commit }, informationList) {
      commit('setInformationList', informationList)
    },
    setAuthList({ commit }, authList) {
      commit('setAuthList', authList)
    },
    setSources({ commit }, sources) {
      commit('setSources', sources)
    },
    setAssetLoading({ commit }, loading) {
      commit('setAssetLoading', loading)
    },
    setAssetInputLoading({ commit }, inputLoading) {
      commit('setAssetInputLoading', inputLoading)
    },
    resetAssetData({ commit }) {
      commit('resetAssetData')
    },
    // Entity
    setSelectedEntity({ commit }, entity) {
      commit('setSelectedEntity', entity)
    },
    setEntityShareDates({ commit }, entityShareDates) {
      commit('setEntityShareDates', entityShareDates)
    },
    // Auth related actions
    setUserData({ commit }, userData) {
      commit('setUserData', userData)
    },
    clearUserData({ commit }) {
      commit('clearUserData')
    },

    // rememberMe
    setRememberMe({ commit }, rememberMe) {
      commit('setRememberMe', rememberMe)
    },
    setUserEmail({ commit }, email) {
      commit('setUserEmail', email)
    },
    // Other actions
    setSnackbarPosition({ commit }, position) {
      commit('setSnackbarPosition', position)
    },
    setLanguage({ commit }, language) {
      commit('setLanguage', language)
    },
  },
  plugins: [vuexPersist.plugin],
})