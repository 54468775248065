<template>
  <div class="w-full max-h-screen min-h-screen overflow-y-auto">
    <navbar v-if="showNavbar" :class="[route.name === 'notFound' && 'hidden']" class="z-50 bg-white shadow-md"/>
    <div :class="{ 'mt-16': showNavbar && route.name !== 'notFound'}">
      <router-view v-slot="{ Component }">
        <component :is="Component"/>
      </router-view>
    </div>
    <ModalsContainer/>
    <ToastNotification ref="toastNotification" :max-toasts="2" :newest-on-top="true"/>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import navbar from '@/components/navigation/navbar/index.vue'
import { ModalsContainer } from 'vue-final-modal'
import ToastNotification from '@/components/toast/index.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const route = useRoute()
const store = useStore()
const toastNotification = ref(null)

// Navbar görünürlük kontrolü
const showNavbar = computed(() => {
  // Auth route'larında navbar'ı gizle
  if (route.path.startsWith('/auth/')) {
    return false
  }

  // NotFound sayfasında navbar'ı gizle
  return route.name !== 'notFound'
})

onMounted(() => {
  store.commit('setSnackbarPosition', { bottom: true, right: true })

  const addToast = (type, text) => {
    toastNotification.value.addToast(type, text)
  }

  const errorHandler = (error) => {
    if (error.status === 401 && responseHandlerService.unauthorizedSnackbarDisplayed) return
    if (error.status === 401) responseHandlerService.unauthorizedSnackbarDisplayed = true

    addToast('warning', error)
  }

  const successHandler = (message) => {
    addToast('success', message)
  }

  const warningHandler = (message) => {
    addToast('warning', message)
  }

  responseHandlerService.setErrorHandler(errorHandler)
  responseHandlerService.setSuccessHandler(successHandler)
  responseHandlerService.setWarningHandler(warningHandler)
})
</script>