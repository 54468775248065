<template>
  <div class="component-layout relative min-h-[80vh]">
    <div v-if="loading" class="loading-overlay">
      <Loading/>
    </div>
    <div v-else class="space-y-6">
      <div class="bg-white p-4 rounded-md w-full">
        <div class="flex flex-col w-full">
          <div class="mb-4">
            <div class="flex items-center justify-between">
              <h3 class="text-lg font-bold text-gray-700">
                {{ t('settings.components.schedule.title') }} </h3>

              <div class="flex items-center space-x-2">
                <Tooltip :display-condition="!suggestedTimes.authorized || !suggestedTimes.hasPropertyId" :title="!suggestedTimes.authorized || !suggestedTimes.hasPropertyId ? t('settings.components.schedule.analyticsRequiredDescription') : ''" color="neutral">
                  <template #content>
                    <Switch v-model="useSuggestedTimes" :disabled="!suggestedTimes.authorized || !suggestedTimes.hasPropertyId" color="primary" @change="toggleSuggestedTimes">
                      <template #label-left>
                        <span class="text-xs text-gray-600">{{ t('settings.components.schedule.suggestedTimes') }}</span>
                      </template>
                    </Switch>
                  </template>
                </Tooltip>
              </div>
            </div>
          </div>

          <div class="overflow-x-auto">
            <table class="w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {{ t('settings.components.schedule.day') }}
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {{ t('settings.components.schedule.times') }}
                </th>
              </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="day in dayOptions" :key="day.day" class="hover:bg-gray-50">
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <input type="checkbox" :id="'day-' + day.day" :checked="isSelectedDay(day)" @change="toggleDay(day)" class="h-3 w-3 text-primary focus:ring-primary border-gray-300 rounded"/>
                    <label :for="'day-' + day.day" class="ml-2 block text-xs font-medium text-gray-900"> {{ t(`general.dayNames.${day.day}`) }} </label>
                    <Tooltip v-if="getSuggestedHours(day.name)" :title="getSuggestedTimeTooltip(day.name, getSuggestedHours(day.name, true))" :display-condition="true">
                      <template #content>
                            <span class="ml-2 text-xs text-blue-600">
                              {{ t('settings.components.schedule.suggestedTime') }}:
                              {{ getSuggestedHours(day.name, true) }}:00
                            </span>
                      </template>
                    </Tooltip>
                  </div>
                </td>
                <td class="px-6 py-4">
                  <div v-if="isSelectedDay(day)" class="overflow-x-auto">
                    <div class="flex md:grid md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-12 gap-2 min-w-max md:min-w-0 py-1">
                      <Tooltip v-for="time in timeOptions" :key="time.time" :title="isSuggestedTime(time, day.name) ? getSuggestedTimeTooltip(day.name, time.time.split(':')[0]) : ''" :display-condition="!!(isSuggestedTime(time, day.name))">
                        <template #content>
                          <button @click="toggleTime(time, day.day)" class="flex-shrink-0 px-2 py-1 rounded-full text-xs transition-colors duration-200 focus:outline-none" :class="[
                            isSelectedTime(time, day.day) ? 'bg-primary text-white' : 'bg-gray-100 text-gray-700 hover:bg-gray-200',
                            isSuggestedTime(time, day.name) ? 'ring-2 ring-blue-400/50 ring-offset-2' : ''
                          ]">
                            {{ time.time }}
                          </button>
                        </template>
                      </Tooltip>
                    </div>
                  </div>
                  <p v-else class="text-xs text-gray-500 italic py-1">
                    {{ t('settings.components.schedule.selectDayFirst') }} </p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="p-2">
            <p class="text-xs text-lobster-500">
              {{ t('settings.components.schedule.timeSelectionLimit') }} </p>
          </div>
        </div>
      </div>

      <!-- Otomatik Yayınlama Kartı -->
      <div class="bg-white shadow rounded-md p-4">
        <div class="flex items-center justify-between">
          <div>
            <h3 class="font-semibold text-gray-800">
              {{ t('settings.components.schedule.automaticPublishingTitle') }} </h3>
            <p class="text-sm text-gray-500 mt-1">
              {{ t('settings.components.schedule.automaticPublishingDescription') }} </p>
          </div>
          <Switch v-model="isAutomaticPublishingEnabled" color="primary" @change="handleAutomaticPublishingChange">
            <template #label-right>
              <span class="text-xs font-medium text-gray-700">
                {{ isAutomaticPublishingEnabled ? t('general.on') : t('general.off') }}
              </span>
            </template>
          </Switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import axios from '@/plugins/axiosInstance'
import { settings } from '@/networking/urlManager'
import { useStore } from 'vuex'
import Loading from '@/components/loadings/loading.vue'
import Tooltip from '@/components/general/tooltip.vue'
import Switch from '@/components/inputs/customSwitch.vue'

const emit = defineEmits(['update:modelValue', 'update:automaticPublishing', 'saving'])
const store = useStore()
const { t } = useI18n()

const selectedDates = ref({ times: [], days: [] })
const automaticPublishing = ref(2)
const loading = ref(true)
const suggestedTimes = ref({
  available: false,
  authorized: false,
  hasPropertyId: false,
  data: {},
})
const useSuggestedTimes = ref(false)
const previousSelections = ref({
  days: [],
  times: [],
})

const dayOptions = [
  { day: 1, name: 'monday' },
  { day: 2, name: 'tuesday' },
  { day: 3, name: 'wednesday' },
  { day: 4, name: 'thursday' },
  { day: 5, name: 'friday' },
  { day: 6, name: 'saturday' },
  { day: 7, name: 'sunday' },
]

const timeOptions = Array.from({ length: 15 }, (_, i) => ({
  time: dayjs().hour(i + 8).format('HH:00'),
}))

const selected = ref({
  days: [],
  times: [],
})

const isAutomaticPublishingEnabled = computed({
  get: () => automaticPublishing.value === 1,
  set: (value) => {
    automaticPublishing.value = value ? 1 : 2
  },
})

const isSelectedDay = computed(() => (day) => {
  return selected.value.days.some((d) => d.day === day.day && d.state !== 3)
})

const isSelectedTime = computed(() => (time, dayId) => {
  const selectedTimesForDay = selected.value.times.filter((t) => t.dayId === dayId)
  return selectedTimesForDay.some((t) => t.time === time.time && t.state !== 3)
})

const getSuggestedHours = (dayName, getHighest = false) => {
  if (!suggestedTimes.value.available || !suggestedTimes.value.data[dayName]) return null
  return getHighest ? suggestedTimes.value.data[dayName][0]?.hour : suggestedTimes.value.data[dayName].map(item => item.hour)
}

const getSuggestedTimeTooltip = (dayName, timeStr) => {
  if (!suggestedTimes.value.available || !suggestedTimes.value.data[dayName]) return ''

  const data = suggestedTimes.value.data[dayName].find(item => item.hour === timeStr)
  if (!data) return ''

  return `Active Users: ${data.activeUsers}
    Sessions: ${data.sessions}
    Bounce Rate: ${data.bounceRate}%
    Conversions: ${data.conversions}
    Revenue: ${data.totalRevenue}`
}

const isSuggestedTime = (time, dayName) => {
  if (!suggestedTimes.value.available || !suggestedTimes.value.data[dayName]) return false
  return suggestedTimes.value.data[dayName].some(item => parseInt(item.hour) === parseInt(time.time))
}

const toggleDay = (day) => {
  const dayIndex = selected.value.days.findIndex((d) => d.day === day.day)
  if (dayIndex > -1) {
    const item = selected.value.days[dayIndex]
    if (item.state === 2) selected.value.days.splice(dayIndex, 1)
    else if (item.state === 3) selected.value.days[dayIndex].state = 1
    else selected.value.days[dayIndex].state = 3
    selected.value.times = selected.value.times.map((item) => ({
      ...item,
      state: selected.value.days.find((day) => item.dayId === day.day)?.state || item.state,
    }))
  } else {
    selected.value.days.push({ ...day, state: 2, assetId: store.state.assetId })
  }
  selectedDates.value = selected.value
}

const toggleTime = (time, dayId) => {
  const selectedTimesForDay = selected.value.times.filter((t) => t.dayId === dayId)
  const timeIndex = selectedTimesForDay.findIndex((t) => t.time === time.time)

  if (timeIndex > -1) {
    const item = selectedTimesForDay[timeIndex]
    const itemIndex = selected.value.times.indexOf(item)
    if (item.state === 2) selected.value.times.splice(itemIndex, 1)
    else if (item.state === 3) selected.value.times[itemIndex].state = 1
    else selected.value.times[itemIndex].state = 3
  } else {
    const isLimitReached = selectedTimesForDay.filter((t) => t.state === 1 || t.state === 2).length >= 3
    if (!isLimitReached) {
      selected.value.times.push({
        dayId,
        time: time.time,
        state: 2,
        assetId: store.state.assetId,
        timezone: 'Europe/Istanbul',
      })
    } else {
      responseHandlerService.handleWarning(t('settings.components.schedule.limitWarning'))
    }
  }
  selectedDates.value = selected.value
}

const removeSuggestedTimes = () => {
  // Store current non-suggested times before removing
  Object.entries(suggestedTimes.value.data).forEach(([dayName, timesData]) => {
    const dayOption = dayOptions.find(d => d.name === dayName)
    if (dayOption && Array.isArray(timesData)) {
      // Her gün için önerilen tüm saatleri kontrol et
      timesData.forEach(timeData => {
        const timeStr = `${timeData.hour.padStart(2, '0')}:00`

        // Önerilen saatleri kaldır ve state 1'leri state 3 yap
        selected.value.times = selected.value.times.filter(time => {
          if (time.dayId === dayOption.day && time.time === timeStr) {
            if (time.state === 2) {
              return false // state 2 olanları kaldır
            }
            if (time.state === 1) {
              time.state = 3 // state 1 olanları state 3 yap
            }
          }
          return true
        })
      })

      // Günün tüm aktif saatlerini kontrol et
      const hasActiveTimesForDay = selected.value.times.some(
          time => time.dayId === dayOption.day && (time.state === 1 || time.state === 2),
      )

      // Eğer günün hiç aktif saati kalmadıysa günü de kaldır/deaktif et
      const dayIndex = selected.value.days.findIndex(d => d.day === dayOption.day)
      if (dayIndex > -1) {
        if (!hasActiveTimesForDay) {
          if (selected.value.days[dayIndex].state === 2) {
            selected.value.days.splice(dayIndex, 1)
          } else if (selected.value.days[dayIndex].state === 1) {
            selected.value.days[dayIndex].state = 3
          }
        }
      }
    }
  })

  // Önceki seçimleri geri yükle
  if (previousSelections.value.days.length > 0) {
    previousSelections.value.times.forEach(time => {
      const existingTimeIndex = selected.value.times.findIndex(
          t => t.dayId === time.dayId && t.time === time.time,
      )
      if (existingTimeIndex === -1) {
        selected.value.times.push(time)
      } else if (selected.value.times[existingTimeIndex].state === 3) {
        selected.value.times[existingTimeIndex].state = time.state
      }
    })

    previousSelections.value.days.forEach(day => {
      const existingDayIndex = selected.value.days.findIndex(d => d.day === day.day)
      if (existingDayIndex === -1) {
        selected.value.days.push(day)
      } else if (selected.value.days[existingDayIndex].state === 3) {
        selected.value.days[existingDayIndex].state = day.state
      }
    })
  }

  selectedDates.value = selected.value
}

const applySuggestedTimes = () => {
  // Store current selections
  previousSelections.value = {
    days: JSON.parse(JSON.stringify(selected.value.days)),
    times: JSON.parse(JSON.stringify(selected.value.times)),
  }

  // Set state 3 only for state 1 selections (previously selected times)
  selected.value.times = selected.value.times.map(time => {
    if (time.state === 1) { // Sadece state 1'leri 3'e çevir
      return { ...time, state: 3 }
    }
    return time
  })

  // Same for days - only change state 1 to state 3
  selected.value.days = selected.value.days.map(day => {
    if (day.state === 1) { // Sadece state 1'leri 3'e çevir
      return { ...day, state: 3 }
    }
    return day
  })

  // Apply suggested times
  Object.entries(suggestedTimes.value.data).forEach(([dayName, timesData]) => {
    const dayOption = dayOptions.find(d => d.name === dayName)
    if (dayOption && timesData.length > 0) {
      // Add day
      const existingDayIndex = selected.value.days.findIndex(d => d.day === dayOption.day)
      if (existingDayIndex === -1) {
        selected.value.days.push({
          ...dayOption,
          state: 2,
          assetId: store.state.assetId,
        })
      } else if (selected.value.days[existingDayIndex].state === 3) {
        selected.value.days[existingDayIndex].state = 2
      }

      // Add all suggested times for the day
      timesData.forEach(timeData => {
        const timeStr = `${timeData.hour.padStart(2, '0')}:00`
        const existingTimeIndex = selected.value.times.findIndex(
            t => t.dayId === dayOption.day && t.time === timeStr,
        )

        if (existingTimeIndex === -1) {
          selected.value.times.push({
            dayId: dayOption.day,
            time: timeStr,
            state: 2,
            assetId: store.state.assetId,
            timezone: 'Europe/Istanbul',
          })
        } else if (selected.value.times[existingTimeIndex].state === 3) {
          selected.value.times[existingTimeIndex].state = 2
        }
      })
    }
  })

  selectedDates.value = selected.value
}

const toggleSuggestedTimes = () => {
  if (!suggestedTimes.value.authorized || !suggestedTimes.value.hasPropertyId) return

  if (useSuggestedTimes.value) applySuggestedTimes()
  else removeSuggestedTimes()
}

const handleAutomaticPublishingChange = (value) => {
  automaticPublishing.value = value ? 1 : 2
}

const initializeData = () => {
  selected.value = {
    days: selectedDates.value.days.map(d => {
      const dayData = dayOptions.find(day => day.day === d.day)
      return { ...d, name: dayData.name, state: 1 }
    }),
    times: selectedDates.value.times.map(t => ({ ...t, state: 1 })),
  }
}

const fetch = async () => {
  loading.value = true
  try {
    const response = await axios.get(settings.scheduleSettings, {
      params: { assetId: store.state.assetId },
    })

    selectedDates.value = response.data.data.scheduling
    automaticPublishing.value = response.data.data.automaticPublishing
    suggestedTimes.value = response.data.data.suggestedTimes || {
      available: false,
      authorized: false,
      hasPropertyId: false,
      data: {},
    }
    useSuggestedTimes.value = response.data.data.isSuggestedTimes === 1
    initializeData()
  } catch (error) {
    responseHandlerService.handleError(error, 'An error occurred')
    console.error(error)
  } finally {
    setTimeout(() => loading.value = false, 500)
  }
}

const save = async () => {
  emit('saving', true)
  await axios.put(settings.scheduleSettings, {
    scheduling: selectedDates.value,
    automaticPublishing: automaticPublishing.value,
    isSuggestedTimes: useSuggestedTimes.value === true ? 1 : 2,
    assetId: store.state.assetId,
  }).then(() => {
    responseHandlerService.handleSuccess(t('settings.successfullyUpdated'))
    store.dispatch('setAutomaticPublishing', automaticPublishing.value)
  }).catch(error => {
    responseHandlerService.handleError(error, 'An error occurred')
    console.error(error)
  }).finally(() => emit('saving', false))
}

// Watch
watch(() => selectedDates.value, (newVal) => {
  if (JSON.stringify(newVal) !== JSON.stringify(selected.value)) {
    initializeData()
  }
}, { deep: true })

onMounted(async () => {
  await fetch()
})

// Bileşen fonksiyonları dışa aktar
defineExpose({
  save,
})
</script>

<style scoped>
.loading-overlay {
  @apply absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10;
}
</style>